.environment-simulator-mini-settings input {
  background-color: #373a45 !important;
  color: #fafaff;
  font-size: 12px;
  font-style: normal !important;
  font-weight: 400;
  padding: 3px 3px;
  border: none;
}

.environment-simulator-mini-settings .settings-select > div {
  background-color: #373a45 !important;
  color: #fafaff !important;
  border: none;
  min-height: auto;
  width: 50px;
}

.environment-simulator-mini-settings .settings-select > div input {
  background-color: transparent !important;
}

.environment-simulator-mini-settings .settings-select > div > div {
  padding: 0;
}

/* arrow */
.environment-simulator-mini-settings .settings-select > div > div > div {
  color: #fafaff !important;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 3px;
}

.environment-simulator-mini-settings h5 {
  color: #b4b6c3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 18px 0px 6px 0px;
}

.environment-simulator-mini-settings .form-check {
  margin: 3px 0;
}

.environment-simulator-mini-settings .form-check label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #fafaff;
  margin-left: 6px;
}

.environment-simulator-mini-settings .react-select .react-select__indicators {
  display: none;
}

.apexcharts-menu {
  background-color: black !important;
}

.apexcharts-menu-open {
  background-color: black !important;
  color: white !important;
}

.apexcharts-menu-item:hover {
  background-color: #1f2124 !important;
}