//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: $gray-100 !important;
  border-color: $gray-100 !important;
}

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

// Show Password
.password-eye {
  &:before {
    font-family: "Material Design Icons";
    content: "\F06D0";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    line-height: 1.2;
    font-size: 16px;
  }
}

.show-password {
  .password-eye {
    &:before {
      content: "\F06D1";
    }
  }
}

.input-group-password {
  .password-eye {
    cursor: pointer;
  }
}


.custom-form {
  .form-label, .form-check-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $carbon-gray-10;
    margin-top: 1rem;
  }

  .items-row {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .flex-1 {
      flex: 1;
    }
  }

  .form-control, .form-select {
    background: $carbon-gray-80;
    color: $carbon-gray-10;
  }
}