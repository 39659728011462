.spinnerGradient {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 5rem;
  background: linear-gradient(#c374ff, #41b0ff);
  animation: animate 1s linear infinite;
}

.spinnerGradient:before {
  position: absolute;
  content: "";
  background: #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  border-radius: 5rem;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}