/* path/filename: Debugger/Debugger.css */
.debugger-console {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 300px;
    background-color: #333;
    color: #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;
    z-index: 999999;;
  }
  
  .debugger-header {
    padding: 10px;
    background-color: #222;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .debugger-body {
    padding: 10px;
    max-height: 250px; /* Adjust height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
  }
  
  .debugger-toggle-button, .debugger-close-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .debugger-toggle-button:hover, .debugger-close-button:hover {
    background-color: #0056b3;
  }
  