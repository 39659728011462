.cardGridContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 4px;
    padding: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    gap: 1.5rem;
    border: 2px solid rgba(131, 197, 196, 0.2);
    

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1064px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (min-width: 1280px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media (min-width: 1440px) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

.cardInGrid {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #1f2124;
    border-radius: 2.5%;
    border: 2px solid transparent;
    box-shadow: 1px 2px 2px black;

    &:hover {
        border: 2px solid cyan;
        cursor: pointer;
    }
}

.cardInGrid > img {
    width: 8rem;
    margin-left: auto;
    margin-right: auto;
}

.cardInGrid > h5 {
    font-weight: 500;
    font-size: 1.2rem;
}

.cardInGridSkeleton {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #1f2124;
    border-radius: 4px;
    border: 1px solid transparent
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 320px;
    height: 320px;
}