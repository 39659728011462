/* .button-full-width {
  width: 200px;
  border: 1px solid #464646;
} */

/* .meng-visualui-topbar-wrapper {
  display: block;
  padding-top: 15px;
} */

.meng-visualui-topbar-buttons {
  display: flex;
  gap: 4px;
}

.meng-visualui-topbar-buttons button, #unsaved-changes-text {
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
}

.meng-visualui-topbar-buttons button.clear{
  background-color: #F95355;
}

.meng-visualui-topbar-buttons button.train{
  background-color: #13A454
}

.visual-ui-node-top-bar-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 12px;
}

.meng-visualui-topbar-node {
  padding: 4px 8px;
  border: 1px solid #464646;
  border-radius: 4px;
  text-align: center;
  cursor: grab;
  color: white;
  font-size: 0.7rem;
}

.meng-visualui-topbar-node.input {
  border-color: #0092d0;
}

.meng-visualui-topbar-node.equation {
  border-color: #0092d0;
}

.meng-visualui-topbar-node.architecture {
  border-color: #8dbb00;
}

.meng-visualui-topbar-node.geometry-group {
  border-color: #c29500;
}

.meng-visualui-topbar-node.constraint {
  border-color: #8800a3;
}

.meng-visualui-topbar-node.dataset {
  border-color: rgb(238, 197, 50);
}

.meng-visualui-topbar-node.validator {
  border-color: #990000;
}

.meng-visualui-topbar-node.monitor {
  border-color: #2930ff;
}

.meng-visualui-topbar-node.visualizer {
  border-color: #17ab00;
}

.meng-visualui-topbar-node.output {
  border-color: #64138f;
}
