.bottom-sidebar-container {
  margin-bottom: 1.5rem;
}

.bottom-sidebar-item {
  color: #cedce4;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  column-gap: 1rem;
  padding-top: 0.5rem;
  &:hover {
    color: white;
    cursor: pointer;
  }
}

.bottom-sidebar-item > span, i {
  color: "#cedce4";
  &:hover {
    color: white;
  }
}

.bottom-sidebar-changelog-unread {
  color: #4dd8ff;
  font-weight: 800;
}