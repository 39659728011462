.meng-visualui .react-flow__node-validator {
  background: #510000;
  border: 1px solid #990000;
  border-radius: 8px;
  color: white;
  padding: 10px;
}

.meng-visualui .react-flow__node-validator .title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.meng-visualui .react-flow__node-validator label {
  display: block;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .react-flow__node-validator file {
  display: block;
  background-color: #990000;
  color: #ffffff;
  font-size: 12px;
  width: 150px;
}

.meng-visualui .react-flow__node-validator input {
  display: block;
  background-color: #990000;
  color: #ffffff;
  font-size: 12px;
}
