.cadViewerContainer {
  width: 100%;
  height: 70vh;
  min-height: 700px;
  position: relative;
  display: flex;
  justify-content: start;
}

.cadViewer {
  width: 100%;
  height: 100%;
  background: rgb(144, 220, 255);
  background: linear-gradient(145deg, rgba(144, 220, 255, 1) 0%, rgba(169, 141, 255, 1) 100%);
}


.cadSidebar {
  position: absolute;
  display: flex;
  background-color: black;
  width: max-content;
  justify-content: end;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
};

.sidebarItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.sidebarItems > input {
  margin-right: 20px;
  margin-top: 5px;
}