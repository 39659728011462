.billing-container {
  .credits-and-stats-container {
    display: grid;
    grid-template-columns: 1;
    gap: 6px;
    margin-bottom: 6px;

    @media (max-width: 768px) {
      .apexcharts-xaxis-texts-g {
        text {
          tspan {
            font-size: 8px !important;
          }
        }
      }
    }

    @media (min-width: 1280px) {
      grid-template-columns: 1fr 2fr;

      .apexcharts-xaxis-texts-g {
        text {
          tspan {
            font-size: 12px !important;
          }
        }
      }
    }

    .credits-container {
      background: #151318;
      padding: 1rem;
      border-radius: 4px;

      .title {
        font-weight: 700;
        font-size: 14px;
      }

      .credits {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          font-weight: 300;
          font-size: 32px;

          &::before {
            content: "$";
            color: white;
            font-weight: 300;
            font-size: 20px;
          }
        }

        .btn {
          padding: 2px 12px;
          text-transform: uppercase;
          border-radius: 30px;
          font-weight: 500;
          font-size: 12px;
          line-height: 10px;
          height: 25px;
          color: #ebedfa;
        }
      }

      .plan-type {
        margin-top: 50px;
        font-weight: 700;
        font-size: 14px;
        display: flex;
        align-items: center;

        i {
          color: black;
          background-color: #56d8e0;
          padding: 4px 8px;
          font-style: normal;
          font-size: 10px;
          border-radius: 20px;
          font-weight: 500;
          margin-left: 8px;
        }
      }

      .storage {
        margin-top: 10px;

        div {
          display: flex;
          justify-content: space-between;

          span {
            font-weight: 400;
            font-size: 12px;
            color: #ebedfa;
          }
        }
      }

      .upgrade-button {
        display: block;
        margin-left: auto;
        margin-top: 1rem;
        padding: 2px 12px;
        text-transform: uppercase;
        border-radius: 30px;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        height: 25px;
        color: #ebedfa;
      }
    }

    .chart-container {
      background: #151318;
      padding: 1rem;
      border-radius: 4px;

      .title {
        font-weight: 700;
        font-size: 14px;
        display: block;
      }

      .credits {
        font-weight: 300;
        font-size: 32px;

        &::before {
          content: "$";
          color: white;
          font-weight: 300;
          font-size: 20px;
        }
      }
    }
  }

  .pricing-container {
    .plans-subtitle {
      max-width: 300px;
    }

    .trial-period-info {
      // font-size: 1.9rem;
      line-height: 2.8rem;
      font-weight: 700;
      margin-top: 1rem;
      margin-bottom: 1rem;
      --bg-size: 400%;
      --color-one: #b76cff;
      --color-two: #55d1f1;
      font-size: clamp(1.9rem, 1.9rem, 5rem);
      background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
      color: transparent;
      background-clip: text;
      // background: linear-gradient(89deg, #55d1f1 20%, #b76cff 70%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    @media (prefers-reduced-motion: no-preference) {
      .trial-period-info {
        animation: move-bg 8s linear infinite;
      }
      @keyframes move-bg {
        to {
          background-position: var(--bg-size) 0;
        }
      }
    }

    .container-cards {
      margin-top: 2rem;

      .card {
        border: none;

        .title {
          font-weight: 400;
          font-size: 16px;
          display: block;
          position: relative;
          width: 100%;
          text-align: center;
        }

        $border-colors: #34c06a, #00bac5, #6b50ff, #ee5884;

        @for $i from 1 through length($border-colors) {
          $color: nth($border-colors, $i);
          &:nth-of-type(#{$i}) {
            &::after {
              content: "";
              position: absolute;
              width: 2px;
              background: #222530;
              height: 80%;
              right: -3px;
              top: 54%;
              transform: translateY(-50%);
            }

            .title::after {
              content: "";
              position: absolute;
              width: 100%;
              background: $color;
              height: 6px;
              left: 0;
              top: 40px;
              border-radius: 6px;
            }
          }
        }
      }

      .price {
        margin-top: 50px;
        margin-bottom: 2rem;
        text-align: center;
        font-weight: 300;
        font-size: 20px;

        &.empty::before {
          content: "";
        }

        i {
          font-size: 13px;
          font-style: normal;
        }

        .trial-period-info {
          font-size: 1.1rem;
          font-weight: 500;
          margin-bottom: -1rem;
          background: -webkit-linear-gradient(45deg, rgba(144, 220, 255, 1) 0%, rgba(169, 141, 255, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .features {
        padding: 8px;
        white-space: pre-line;

        .feature {
          display: block;
          font-size: 12px;
          opacity: 0.7;
          text-align: center;
          margin-bottom: 10px;
        }
      }

      .btn {
        margin-top: auto;
        width: fit-content;
        align-self: center;
        position: relative;
        top: 2rem;
      }
    }
  }
}
