.datasets-list-page {
  //overrites
  .page-aside-left {
    padding: 0;
  }

  .card-body {
    padding: 1rem !important;
  }

  // NEW
  .left-panel {
    .navigation {
      display: flex;
      flex-direction: column;
      gap: 15px;

      a {
        font-size: 14px;
        font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        line-height: 17px;

        i {
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }

    .bottom-part {
      margin-top: 50px;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;

      .custom-row {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .top-part {
    .search-container {
      display: flex;

      * {
        color: $carbon-gray-60;
      }

      input {
        background-color: $carbon-gray-80;
      }

      button {
        box-shadow: inset 0px 0px 0px 4px #373a45;
        border: 0;
        margin-left: -5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }

    .top-right-buttons {
      display: flex;
      gap: 5px;

      .btn {
        padding: 2px 6px;

        &.active {
         background-color: $carbon-gray-90;
         border: none;
        }

        i {
          font-size: 16px;
        }
      }
    }
  }

  .table {
    border-bottom: 2px solid $carbon-gray-80;
    margin-bottom: 1rem;

    thead {
      background: $carbon-gray-90;

      th {
        padding: 9px 6px;

        &:first-of-type {
          padding-left: 32px;
        }

        &:last-of-type {
          text-align: end;
        }
      }
    }

    tr {
      border-bottom: 1px solid $carbon-gray-90;

      td {
        padding: 8px 6px;

        span, a{
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          color: $carbon-gray-10;

          i {
            font-size: 16px;
            color: $carbon-gray-50;
            cursor: pointer;
          }
        }

        &.format {
          text-transform: uppercase;
        }

        &.action span {
          justify-content: end;
        }
      }
    }
  }

  .pagination-custom {
    display: flex;
    justify-content: end;
  }
}