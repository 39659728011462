.button-full-width {
  width: 100%;
  border: 1px solid #464646;
}

.meng-visualui aside {
  padding: 10px;
  font-size: 0.67rem;
  background: #0D101B;
  color: #fff;
  flex-direction: column;
  position: absolute;
  right: 0px;
  height: 70vh;
  min-height: 700px;
  width: 320px;
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;  
}

.meng-visualui aside h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  color: white;
  margin: 0;
}

.meng-visualui aside h3 {
  font-size: 0.8rem;
}

.meng-visualui aside .c-row {
  display: grid;
  grid-template-columns: 131px 105px 1fr;
  gap: 2px;
  margin-top: 2px;
}

.meng-visualui aside .c-row span{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #B4B6C3;
}

.meng-visualui aside .c-row .col:first-child {
  width: 131px;
}
.meng-visualui aside .c-row .col:nth-child(2) {
  width: 105px;
}

.meng-visualui aside .btn-remove {
  padding: 2px 8px;
  border: none;
  background-color: #61646F;
}

.meng-visualui aside .btn-remove-small {
  padding: 2px 3px;
  border: none;
  background-color: #61646F;
}

.meng-visualui aside .btn-add {
  padding: 2px 8px;
  border: none;
  background-color: #61646F !important;
  width: 100%;
  color: #FAFAFF !important;
}

.meng-visualui aside input {
  background-color: #373A45 !important;
  color: #FAFAFF;
  font-size: 12px;
  font-style: normal !important;
  font-weight: 400;
  padding: 3px 8px;
  border: none;
}

.meng-visualui aside .settings-select > div {
  background-color: #373A45 !important;
  color: #FAFAFF !important;
  border: none;
  min-height: auto;
}

.meng-visualui aside .settings-select > div input {
  background-color: transparent !important;
}

.meng-visualui aside .settings-select > div > div {
  padding: 0;
}

/* arrow */
.meng-visualui aside .settings-select > div > div > div {
  color: #FAFAFF !important;
  font-size: 12px;
  font-weight: 400;
  padding: 0.7px 8px;
}

.meng-visualui aside .react-select__multi-value {
  background: #61646F !important;
  padding: 0 4px !important;
  display: flex;
}

.meng-visualui aside .react-select__multi-value div{
  padding: 0;
  margin: 0;
}

.meng-visualui aside .react-select__multi-value__label {
  color: #FAFAFF !important;
  font-size: 13px;
  font-weight: 400;
  padding: 2px;
  height: 22px;
}

.meng-visualui aside .react-select__multi-value__remove {
  color: #FAFAFF !important;
  margin: 0 0 0 4px !important;
}

.meng-visualui aside h5 {
  color: #B4B6C3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 18px 0px 6px 0px;
}

.meng-visualui aside .form-check {
  margin: 3px 0;
}

.meng-visualui aside .form-check label{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #FAFAFF;
  margin-left: 6px;
}

.meng-visualui .react-select .react-select__indicators{
  display: none;
}