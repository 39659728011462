@use "sass:math";
//
// card.scss
//

.card {
  border: 1px solid $gray-100;
  margin-bottom: $grid-gutter-width;
  background: $bg-color;

  .header-title {
    margin-bottom: 2rem;
    letter-spacing: 0.02em;
    font-size: 1rem;
    margin-top: 0;
    font-weight: 400;
    color: $carbon-gray-10;
  }
  .card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
  }
  .card-widgets {
    float: right;
    height: 16px;
    > a {
      color: inherit;
      font-size: 18px;
      display: inline-block;
      line-height: 1;

      &.collapsed {
        i {
          &:before {
            content: "\F415";
          }
        }
      }
    }
  }

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: $carbon-gray-10;
  }
}

.card-link {
  &:hover {
    background: #141116;
  }
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: $card-border-radius;
  background: rgba($white, 0.8);
  cursor: progress;

  .card-portlets-loader {
    background-color: $card-loader-bg;
    animation: rotatebox 1.2s infinite ease-in-out;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -(math.div($grid-gutter-width, 2));
    margin-top: -(math.div($grid-gutter-width, 2));
  }
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

// Pricing card

.card-pricing {
  position: relative;

  .card-pricing-plan-name {
    padding-bottom: 20px;
  }

  .card-pricing-icon {
    font-size: 22px;
    box-shadow: $box-shadow;
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 56px;
    border-radius: 50%;
  }

  .card-pricing-price {
    padding: 30px 0 0;

    span {
      font-size: 40%;
      color: $gray-600;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  .card-pricing-features {
    color: $gray-600;
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0;

    li {
      padding: 15px;
    }
  }
}

.card-pricing-recommended {
  @include media-breakpoint-up(lg) {
    margin-top: -1.9375rem;
  }

  .card-pricing-plan-tag {
    background-color: rgba($danger, 0.2);
    color: $danger;
    padding: 5px 0;
    font-weight: 700;
    border-radius: 0.25rem 0.25rem 0 0;
    margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  }
}

// Custom card height

.card-h-100 {
  height: calc(100% - #{$grid-gutter-width});
}

@mixin iteration-style-card {
  .simulator-chart-container {
    padding: 1rem;
    border-radius: 4px;
    background-color: #000000;
    background-color: $carbon-gray-90;
    display: flex;
    flex-direction: column;
  
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $carbon-gray-10;
    }

    .percentage-completed {
      display: flex;
      align-items: baseline;
      gap: 4px;

      div {
        &:first-of-type {
          span {
            font-weight: 300;
    
            &:first-child {
              font-size: 32px;
    
              span {
                font-size: 20px;
              }
            }
            &:last-child {
              font-size: 13px;
            }
          }
        }
      }
    }

    .apex-charts {
      margin-top: -4px;
      width: 100%;

      svg {
        background: $carbon-gray-80 !important;
      }
    }
  }

  .iteration-statistics {
    display: flex;
    justify-content: space-between;

    span {
      &:nth-of-type(1) {
        color: $carbon-gray-50;

        &.iterations-text {
          font-weight: 600;
        }
      }
    }
  }

  .iteration-details {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    
    div {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      &:nth-child(2) {
        text-align: right;
      }

      .title {
        font-size: 13px;
        color: $carbon-gray-50;
      }
    }
  }
}


// Used in overview
.latest-activity-card {
  .latest-activity-simulators-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-top: 8px;

    @include iteration-style-card;
  }

  .in-progress-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .stats {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        display: flex;
        gap: 5px;
        align-items: baseline;
        width: 45%;

        span {
          &:first-of-type {
            font-size: 32px;
          }
        }
      }
    }
  }
}

// used on list page
.simulator-card {
  // max-width: 250px;
  transition: 0.3s;
  height: 100%;

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;
    justify-content: space-between;

    .top-row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $carbon-gray-80;
      width: 100%;

      .chart-container {
        max-height: 77px;
        max-width: 77px;
        display: flex;
        align-items: flex-end;
      }

      h4 {
        margin: 0;
  
        .text-title {
          font-size: 14px;
          font-weight: 700;
          margin: 0;
        }
      }

      .accuracy-container {
        display: flex;
        align-items: baseline;
        gap: 4px;
    
        span {
          font-weight: 300;
    
          &:first-child { 
            font-size: 32px;
    
            span {
              font-size: 20px;
            }
          }
          &:last-child {
            font-size: 13px;
          }
        }
      }
    }

    .stats {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid $carbon-gray-80;

      div {
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
          font-weight: 600;

          &:first-of-type {
            color: $carbon-gray-50;
          }
        }
      }
    }

    p {
      padding: 8px 0;
    }
  }
}

.card-single-project {
  position: relative;

  .badge {
    top: 12px;
    right: 12px;
    position: absolute;
  }
}

//main list page
.enviroment-card {
  @include iteration-style-card;

  .simulator-chart-container {
    background: transparent;
    padding: 0;
  }

  .percentage-completed {
    display: flex;
    justify-content: space-between;
  }

  .iteration-details {
    border-bottom: 1px solid $carbon-gray-80;
    padding-bottom: 8px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: $carbon-gray-50;
    margin-top: 8px;
  }
};