//
// _badges.scss
//

// Lighten badge (soft)

@each $color, $value in $theme-colors {
  .badge-#{$color}-lighten {
    @include badge-variant-light($value);
    &[href] {
      color: $value;
      text-decoration: none;
      background-color: rgba($value, 0.18);
    }
    &[href] {
      &:hover,
      &:focus {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.4);
      }
    }
  }
}

// Outline badge

@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-variant-outline($value);
    &[href] {
      color: $value;
      text-decoration: none;
      background-color: rgba($value, 0.2);
    }
    &[href] {
      &:hover,
      &:focus {
        color: $value;
        text-decoration: none;
        background-color: rgba($value, 0.2);
      }
    }
  }
}

.badge {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  width: fit-content;

  &.running {
    background: $green-40 !important;
  }
  &.stopped {
    background: $red-50 !important;
  }
  &.starting {
    background: $yellow !important;
  }
  &.stopping {
    background: $orange-40 !important;
  }
  &.blue {
   background-color: $blue-50;
  }
  &.not_available {
    background-color: rgb(180, 178, 178) !important;
  }
}