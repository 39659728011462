.dropzone {
    width: 200px;
    min-height: 80px;
    border: 2px dashed #2a2c2f;
    background: #1f2124;
    border-radius: 6px;
    cursor: pointer;
    padding: 2px 5px 10px;
}

.dzNessage {
    text-align: center;
    margin: 0.2rem 0;
    outline: none;
}

.fileCard {
    max-width: 200px;
}