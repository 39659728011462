.meng-visualui .monitor-node {
  background: #11159c;
  border: 1px solid #2930ff;
  border-radius: 8px;
  color: white;
  padding: 10px;
}

.meng-visualui .monitor-node .title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: -3px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 10px 10px;
  border-bottom: 1px solid #2930ff;
}

.meng-visualui .monitor-node label {
  display: block;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .monitor-node input {
  display: block;
  background-color: #11159c;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .monitor-node select {
  display: block;
  background-color: #11159c;
  color: #ffffff;
  font-size: 12px;
}
