.btnCopySimulatorDark {
  border: 1px solid #61646F;
  background-color: transparent;
  color: #61646F;
  padding: 3px 20px;
  border-radius: 25px;
  height: min-content;
  &:hover {
    color: white;
    background-color: #1e1e1e !important;
  }
}

.labelAndButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (min-width: 1024px) {
    flex-direction: row;  
  }
}

.titleChevronContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.buttonsContainer {
  display: flex;
  gap: 0.5rem;
  flex: grow;
}