.meng-visualui .constraint-node {
  border: 1px solid #5d1883;
  border-radius: 4px;
  color: white;
  min-width: 200px;
}

.meng-visualui .selected .constraint-node{
  border: 1px solid #A72DEA;
}

.meng-visualui .constraint-node .title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  background: #59137D;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.meng-visualui .constraint-node .content {
  padding: 8px;
  background-color: #0D101B;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.meng-visualui .constraint-node .react-select > div  {
  background: #373A45 !important;
  color: #ffffff;
  padding: 0px 8px;
  min-height: auto;
}

.meng-visualui .constraint-node .react-select > div > div {
  padding: 0;
}

.meng-visualui .constraint-node .react-select > div > div > div  {
  font-size: 12px;
  line-height: 1;
  color: #B4B6C3;
}

.meng-visualui .constraint-node .react-select > div div span  {
  display: none;
}

.meng-visualui .constraint-node label {
  display: block;
  color: #ffffff;
  font-size: 12px;
}