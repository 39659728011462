.enviroment-single {
  background-color: #151318;
  padding: 1rem;
  position: relative;

  .edit-container {
    position: absolute;
    right: 1rem;

    i {
      font-size: 20px;
    }
  }

  .title {
    display: block;
  }

  .badge {
    display: block;
    margin-top: 10px;
  }

  .content-grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media (min-width: 1280px) {
      grid-template-columns: 450px 1fr;
    }

    .simulators-container {
      .title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      .list {
        .simulator-line {
          font-size: 14px;
          line-height: 14px;
          border-bottom: 1px solid #222530;
          margin-bottom: 1rem;

          i {
            color: #7C7F8B;
          }

          span {
            color: #EBEDFA;
          }
        }
      }

      .server-info {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        strong {
          color: #7C7F8B;
        }

        span {
          color: #EBEDFA;
        }
      }
    }

    .metrics {
      .nav-tabs {
        border-bottom: 2px solid #373A45;
        background-color: transparent;

        li {
          border: none;

          button {
            font-size: 14px;
            background-color: transparent;
            color: #61646F;
            border: none;
            border-radius: 0;
            margin-bottom: -2px;

            &.active {
              border-bottom: 2px solid #7CA8FF;
              color: #D0D2DF;
              font-weight: 700;
            }
          }
        }
      }

      .tab-content {
        .card {
          border: none;
        }

        .card-body {
          padding: 0;
        }
      }
    }
  }
}