.responsiveIframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .relative {
    /* position: relative;  */
    /* aspect-ratio: 16 / 9; */
    /* padding-bottom: 100%; */
    /* padding-bottom: 56.25%; */
    /* overflow: hidden; */
    max-height: 83vh;
    /* width: 80vh; */
}
.absolute {
    position: absolute;
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
    pointer-events: none;
}
.div[class^="leva-"], div[class*=" leva-"]
{
    pointer-events: initial;
}
.leftBottomPanel {
    background: #181c20;
    border-radius: var(--leva-radii-lg);
    box-shadow: var(--leva-shadows-level1);
    padding: 10px;
    pointer-events: all;
}
.pixelStreamingVideo {
    height: 88vh;
    width: 91vw; 
    max-height: 100vh;
    object-fit: contain;
    min-height: -webkit-fill-available;
    overflow: hidden;
}

/* Magnitude scale */

.magnitude_scale_container {
    width: 40px;
    height: 20px;
    border: 1px solid black;
    cursor: pointer;
    position: absolute;
    right: 2%;
    bottom: 6%;
    z-index: 99999;
    background: #272b30;
  }
  
 .pixelContainer {
    margin-left: calc(var(--bs-card-spacer-y) * -1);  /* negate card padding */
 }
