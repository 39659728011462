//
// text.scss
//

// Weight

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}

.text-blue {
  color: $blue-50;
}

.text-muted {
  color: #6b6f82 !important;
}
