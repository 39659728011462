//
// Manrope fonts
//

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-ExtraLight.ttf");
  src: local("Manrope ExtraLight"), local("Manrope-Light"), url("../../../fonts/Manrope-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-Light.ttf");
  src: local("Manrope Light"), local("Manrope-Light"), url("../../../fonts/Manrope-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-Regular.ttf");
  src: local("Manrope Regular"), local("Manrope-Regular"), url("../../../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-Medium.ttf");
  src: local("Manrope Medium"), local("Manrope-Medium"), url("../../../fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-SemiBold.ttf");
  src: local("Manrope SemiBold"), local("Manrope-SemiBold"),
    url("../../../fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-Bold.ttf");
  src: local("Manrope Bold"), local("Manrope-Bold"), url("../../../fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../../../fonts/Manrope-ExtraBold.ttf");
  src: local("Manrope ExtraBold"), local("Manrope-ExtraBold"),
    url("../../../fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
