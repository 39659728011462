.meng-visualui {
  background: #222530;
}

/* .meng-visualui .button-group {
  display: flex;
} */

/* .meng-visualui .button {
  display: flex;
  padding: 10px;
  font-weight: bold;
  font-size: 21px;
  border: 2px solid #0041d0;
  background: #ccd9f6;
  margin: 0 10px;
  border-radius: 10px;
  color: #0041d0;
}

.meng-visualui .button:hover {
  background: #0041d0;
  color: white;
}

.meng-visualui .button:disabled {
  background: #ddd;
  color: #9ca8b3;
  border-color: #9ca8b3;
  cursor: not-allowed;
} */

/* .meng-visualui .button-icon {
  transform: rotate(-90deg);
  display: block;
  margin-right: 5px;
} */

/* .meng-visualui .button-icon:last-child {
  margin-left: 5px;
} */

.meng-visualui .react-flow__controls {
  display: flex;
  gap: 3px;
  flex-direction: column;
}

.meng-visualui .react-flow__controls-button {
  background: #151318;
  color: #9f9f9f;
  border: none;
  border-radius: 4px;
}

.meng-visualui .react-flow__controls-button:hover {
  background: #3e3e3e;
  color: white;
}

.meng-visualui .react-flow__controls-button svg {
  fill: #7b7b7b;
}

.meng-visualui .react-flow__node {
  fill: white;
}

.meng-visualui .react-flow__node .node-icons {
  float: right;
  margin-top: 0px;
  font-size: 0.8rem;
}

.meng-visualui .react-flow__handle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #000000;
  border-color: #aaaaaa;
  transition: all 0.2s ease;
  transform-origin: center center;

  &:hover, &:active {
    transform: scale(2);
  }
}

.meng-visualui .react-flow__edge-path {
  stroke: #D0D2DF;
  stroke-width: 2;
}

/* .meng-visualui .temp .react-flow__edge-path {
  stroke: #bbb;
  stroke-dasharray: 5 5;
} */

.meng-visualui .react-flow__node .node-icons i {
  margin-left: 10px;
}

.meng-visualui .reactflow-wrapper {
  height: 650px;
  margin-top: 15px;
}

/* .meng-visualui .selectall {
  margin-top: 10px;
} */

@media screen and (min-width: 768px) {
  .meng-visualui {
    flex-direction: row;
  }
}
/* .react-flow__node.react-flow__node-equation.nopan.selectable:hover,
.react-flow__node.react-flow__node-architecture.nopan.selectable:hover,
.react-flow__node.react-flow__node-geometryGroup.nopan.selectable.parent:hover,
.react-flow__node.react-flow__node-constraint.nopan.selectable:hover {
  outline: 1px solid gray;
  border-radius: 8px;
}
.react-flow__node.react-flow__node-equation.nopan.selected.selectable {
  outline: 1px solid greenyellow;
  border-radius: 8px;
}
.react-flow__node.react-flow__node-architecture.nopan.selected.selectable {
  outline: 1px solid greenyellow;
  border-radius: 8px;
}
.react-flow__node.react-flow__node-geometryGroup.nopan.selected.selectable.parent {
  outline: 1px solid greenyellow;
  border-radius: 8px;
}
.react-flow__node.react-flow__node-constraint.nopan.selected.selectable {
  outline: 1px solid greenyellow;
  border-radius: 8px;
} */