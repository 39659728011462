.meng-visualui .react-flow__node-viz2DImg, .meng-visualui .react-flow__node-viz3D {
  background: #094200;
  border: 1px solid #17ab00;
  border-radius: 8px;
  color: white;
  padding: 10px;
}

.meng-visualui .react-flow__node-viz2DImg .title, .meng-visualui .react-flow__node-viz3D .title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.meng-visualui .react-flow__node-viz2DImg label, .meng-visualui .react-flow__node-viz3D label {
  display: block;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .react-flow__node-viz2DImg input, .meng-visualui .react-flow__node-viz3D input {
  display: block;
  background-color: #107500;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .react-flow__node-viz2DImg select, .meng-visualui .react-flow__node-viz3D select {
  display: block;
  background-color: #107500;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .react-flow__node-viz2DImg img, .meng-visualui .react-flow__node-viz3D img {
  margin-top: 10px;
}

.meng-visualui .react-flow__node-viz2DImg .viz-2d-img-empty {
  width: 100px;
}

.meng-visualui .react-flow__node-viz2DImg .viz-2d-img {
  min-width: 150px;
  max-width: 300px;
  width: 100%;
}

.meng-visualui .react-flow__node-viz2DImg .viz-2d-img.validator-img {
  min-width: 300px;
  max-width: 600px;
  width: 100%;
}
