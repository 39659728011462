.current-plan-badge {
    padding: 3px 15px;
    border-radius: 15px;
    background-color: #56D8E0;
    margin-top: 12px;
    color: black;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      background-color: #068fe5;
    }

  }
.current-plan-badge a {
    color: black;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: white;
    }
}