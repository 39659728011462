.meng-visualui .dataset-node {
  border: 1px solid rgb(238, 197, 50);
  border-radius: 4px;
  color: white;
  min-width: 200px;
}

.meng-visualui .selected .dataset-node{
  border: 1px solid rgb(172, 138, 13);
}

.meng-visualui .dataset-node .title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  background: rgb(166, 132, 7);
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.meng-visualui .dataset-node label {
  display: block;
  color: #ffffff;
  font-size: 12px;
}

.meng-visualui .dataset-node .content {
  padding: 8px;
  background-color: #0D101B;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.meng-visualui .dataset-node .react-select > div  {
  background: #373A45 !important;
  padding: 0px 8px;
  min-height: auto;
}

.meng-visualui .dataset-node .react-select > div > div {
  padding: 0;
}

.meng-visualui .dataset-node .react-select > div > div > div  {
  font-size: 12px;
  line-height: 1;
  color: #B4B6C3;
}

.meng-visualui .dataset-node .react-select > div div span  {
  display: none;
}

.meng-visualui .dataset-node input {
  display: block;
  background-color: rgb(238, 197, 50);
  color: #ffffff;
  font-size: 12px;
}

/* fix KaTeX */
.katex-display > .katex {
  display: block;
  text-align: center;
  white-space: normal !important; /* changed nowrap to normal */
}

.nav-tabs .nav-link {
  color: white !important;
  background-color: #373A45;
}