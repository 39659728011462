.siml-loader-component {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: calc(100vh - (51px + 56px));
}

.siml-loader-container {
  display: flex;
  width: 25px;
  height: 25px;
  flex-direction: column;
  position: absolute;
  top: 50%;
}

#left {
  animation-name: slide-left;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

#right {
  animation-name: slide-right;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  scale: 1.03;
}

@keyframes slide-left {
  from {
    transform: translate(5%,0);
  }

  to {
    transform: translate(5%, -30%);
  }
}

@keyframes slide-right {
  from {
    transform: translate(45%, -105%);
  }

  to { 
    transform: translate(50%, -75%);
  }
}