//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  // float: right !important;
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none !important;
  }

  button[disabled] {
    color: $gray-500 !important;
  }
}

.pagination-custom {
  .page-item {
    * {
      color: $carbon-gray-10;
      font-size: 14px;
    }

    &.active {
      position: relative;

      span {
        border: none;
        background: none;
      }

      &::after {
        content: '';
        position: absolute;
        height: 4px;
        background: $blue-50;
        width: 100%;
        bottom: 0;
      }
    }

    a {
      color: $carbon-gray-10;
      font-size: 14px;
      border: none;
      background: none;
    }
  }
}