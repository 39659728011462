.thumbnailSimulator {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: min-content;
  align-self: center;
}

.insideCardContainer {
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.insideCardContainer > img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}